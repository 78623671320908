.list {
  width: 100%;
  max-width: 360;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 750px;

  .listItem {
      word-wrap: break-word !important;
  }

}