.form {
  flex-direction: column;
  justify-content: center;
  padding: 10px 0 40px 0;

  .input {
    width: 100% !important;
  }

  .submit {
    margin-top: 20px;
    max-height: 63px;
  }
}

.card {
  margin-top: 28px;
  max-width: 600px;
}