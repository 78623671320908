.expirationDate {
  text-align: center;
}

.greenSolution {
  color: red;
}

.greenPlusIcon {
  color: #70AE6E;
  height: 2vh !important;
}


.container {
  border: 1px solid #D3D3D3;
  padding: 5px;

  .containerRow {
    border-bottom: 1px solid #D3D3D3;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 5px;
  }

}

.details {
  font-size: 12px;
}

.submit {
  margin-top: 20px;
}

.secureFixedDetails {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 15px 0 15px 0;

  .secureFixDescription {
    padding-top: 7px;
    text-align: center;
  }
}
