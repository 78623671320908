.woodriverImg {
  align-items: center;
  display: flex;
}

.card {
  max-width: 1000px;
  margin: 0 auto;
}

.phoneLink {
  text-decoration: none;
  color: inherit;
}

.choiceDescription {
  max-width: 800px;
  text-align: center;
}

.error {
  color: red;
}
