
.container {
  width: 95%;
}
.headerRow {
  font-weight: bold;
}

.error {
  color: red;
}

.customRow {
  padding: 5px 10px 5px 10px;
  border-bottom: 1px solid #D3D3D3;
}

.form {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  .contactBhe {
    text-align: center;
  }
  .emailContainer {
    margin-top: 15px;
    width: 100%;
    .input {
      width: 90%;
    }
  }
  .submit {
    margin-top: 20px;
  }
}

.tableRow {
  td:first-child {
      width: 15%;
  }
  td:nth-child(2) {
      width: 15%;
  }
  td:nth-child(3) {
      width: 10%;
  }
  td:nth-child(4) {
      width: 10%;
  }
  td:nth-child(5) {
      width: 20%;
  }
  td:nth-child(6) {
    text-align: center !important;
    width: 15%;
  }
  .tableCell {
    padding: 8px !important;
    h5 { 
      margin: 0px;
    }
  }
  .bheError {
    color: red;
  }
  .bheComplete {
    color: #2E7D32;
  }
}


