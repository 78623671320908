.headerRow {
  font-weight: bold;
}

.customRow {
  padding: 5px 10px 5px 10px;
  border-bottom: 1px solid #D3D3D3;
  height: 100%;
  width: 100%;
}

.expandArrow {
  cursor: pointer;
  height: 1em;
  width: 1em;
}
