.gridList {
  width: 500px;
  max-height: 450px;
}

.root {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden;
}