.fullCell {
  width: 100%;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  border-left: 1px solid grey;

  &:last-child {
    border-right: 1px solid grey;
  }

  .monthCell {
    text-align: center;
    border-bottom: 1px solid grey;
  }

  .volumeCell {
    text-align: center;
  }
}
