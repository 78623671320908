.paper {
  margin-top: 15px;
  min-width: 100%;
  overflow-x: auto;
  margin-bottom: 15px;
}

.table {
  min-width: 650;
}

.grid {
  width: '100%';
  .react-grid-HeaderCell,
  .rdg-selected,
  .react-grid-Cell {
      box-sizing: border-box !important;
  }
}