.hideSubmit {
  display: none !important;
  opacity: 0;
}

.default {
  margin-top: 25px !important;
  -webkit-animation: fadein 2s; 
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

@-webkit-keyframes fadein {
  0% { opacity: 0; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

@keyframes fadein {
  0% { opacity: 0; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}