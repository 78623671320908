.input {
  margin: 8px !important;
  width: 95%;
}

.selectField {
  margin: 8px !important;
  width: 95%;
}

.panel {
  width: 100%;
}