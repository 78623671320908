:global {
  .isActive {
    font-weight: bold;
    color: red;
  }
  .react-swipeable-view-container > div[aria-hidden="false"] {
    height: 100%;
  }
  .react-swipeable-view-container > div[aria-hidden="true"] {
    height: 0;
  }
}
