.baseStyle {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 80px;
  border-width: 2;
  border-radius: 2;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
};
​
.activeStyle {
  border-color: #2196f3;
};
​
.acceptStyle {
  border-color: #2CB2D0;
};
​
.rejectStyle {
  border-color: #ff1744;
};
​

.text {
  color: #BDBDBD;
};
​
.loadingContainer {
  width: 550px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
};
​
.errorText {
  color: #ED4740;
};