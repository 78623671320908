.nachaReportTable {
  overflow-x: auto;
}

.generalErr {
  margin: 0 auto;
  border: 1px solid red;
  border-radius: 3px;
  background-color: #FFB7B7;
  width: 400px;

  p {
    padding: 5px;
    text-align: center;
  }
}

.radioText {
  span {
    font-size: 12px;
  }
}