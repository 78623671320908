@import './base/base';
@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  font-family: 'Lato', sans-serif;
  width: 100%;
}


.rdg-selected,
.react-grid-HeaderCell,
.react-grid-Cell {
    box-sizing: border-box;
}

.react-grid-HeaderRow {
  height: 150px;
}

$white: #fefeff;
$black: #040404;
$primary: #70AE6E;
$primaryLight: #88BC86;
$primaryDark: #3E855E;
$secondary: #007799;
$secondaryLight: #74B3CE;
$secondaryDark: #06547A;