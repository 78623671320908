.fadeOut {
  -webkit-animation: fadeOutAnimation 2s infinite;
  -moz-animation: fadeOutAnimation 2s infinite;
  -o-animation: fadeOutAnimation 2s infinite;
  animation: fadeOutAnimation 2s infinite;
}

@keyframes fadeOutAnimation {
  10% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeOutAnimation {
  10% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeOutAnimation{
  10% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
@-webkit-keyframes fadeOutAnimation{
  10% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}