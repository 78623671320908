.form {
  width: 100%;
  padding: 10px;
  .selectField {
    width: 100%;
    margin: 8px;
  }
  .input {
    width: 100%;
    margin: 8px;
  }
}

