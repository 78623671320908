.wrapper{
  margin-top: 20px;
  width: 100%;
  
  .accountInfo {
    margin: 2px 0 2px 0;
  }

  .usageWrapper {
    display: flex;
    justify-content: space-evenly;
    margin: 20px 0 20px 0;
  }
}

.visible {
  height: 100%; opacity: 1;
  transition: height 0ms 0ms, opacity 600ms 0ms;
}