.card {
  width: 90%;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.form {
  display: flex;
  justify-content: center !important;
  text-align: center;
  padding: 10px 0 40px 0;

  .error {
    color: red;
  }
}

.emailQuoteDescription {
  text-align: center;
  margin: 60px 0 10px 0;
}

.emailSent {
  width: 100%;
  text-align: center;
}
