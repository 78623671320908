.root {
  width: 100%;
  height: 100%;
  max-height: 65em;
  margin-top: 5px;
  overflow-x: auto;
}

.table {
  width: 100%;
  height: 100%;
}

th {
  width: 25px !important;
}

tr {
  height: 10px !important;
  font-size: 12px !important;
  width: 15px !important;
  padding: 5px 5px 5px 5px !important;
}

tr td {
   height: auto !important;
   font-size: 12px !important;
   width: 15px !important;
   padding: 5px 5px 5px 5px !important;
}