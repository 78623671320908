.grid {
  .react-grid-HeaderCell,
  .rdg-selected,
  .react-grid-Cell {
      box-sizing: border-box !important;
  }
}

.button {
  height: 35px !important;
  margin: 5px;
  text-align: center;
}
