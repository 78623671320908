.icon {
  color: white;
}

.button {
  span {
    color: white;
  }

  border: 1px solid #f5f5f5 !important;
  margin: 10px;
}
.grow {
  flex-grow: 1;
}