form {
  width: 100%;

  .input {
    width: 100%;
  }
  .submit {
    width: 100%;
  }
}
.error {
  color: red;
}

.alignLeft {
  text-align: left;
}